import Data from "@data/sections/services.json";
import Link from "next/link";
import Image from "next/image";


const Services2Section = ( { introHidden, noPaddingBottom, buttonHidden } ) => {
  return (
    <section className={noPaddingBottom ? "onovo-section gap-top-100" : "onovo-section gap-top-100 gap-bottom-80"}>
        <div className="container">
            {introHidden == undefined &&
                <>
                    {/* Heading */}
                    <div className="onovo-heading gap-bottom-40 align-center">
                        <h2 className="onovo-title-2">
                            <span dangerouslySetInnerHTML={{__html: Data.title}} />
                        </h2>
                    </div>

                    {/* Description */}
                    <div className="row gap-bottom-60">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-10 offset-lg-1 align-center">
                            <div className="onovo-text">
                                <p dangerouslySetInnerHTML={{__html: Data.text}} />
                            </div>
                        </div>
                    </div>
                </>
            }

            {/* Services items */}
            <div className="row gap-row">
                {Data.items.map((item, key) => (
                <div key={`services2-item-${key}`} className="col-xs-12 col-sm-12 col-md-6 col-lg-4 align-left">
                    <div className="onovo-service-item">
                        <div className="image">
                            <Link href={item.link}>
                                <Image src={item.icon} alt={item.title} width={100} height={100} />
                            </Link>
                        </div>
                        <div className="onovo-service-item-inner onovo-hover-3 onovo-hover-black">
                            <h5 className="title">
                                <Link href={item.link}>
                                    <span data-splitting data-onovo-scroll>{item.title}</span>
                                </Link>
                            </h5>
                            <div className="list">
                                <ul>
                                    {item.list.map((elem, key2) => (
                                    <li key={`services2-item-${key}-list-element-${key2}`}>
                                        <Link className="onovo-lnk" href={elem.link}>
                                            <span data-splitting data-onovo-scroll>{elem.title}</span>
                                        </Link>
                                    </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                ))}
            </div>
            {!buttonHidden ? (
            <div className="row my-5">
                <div className="col-lg-12 d-flex justify-content-center">
                    <Link className="onovo-btn onovo-hover-btn" href={'/services'}>
                        <i className="arrow"><span /></i>
                        <span>View All Services</span>
                    </Link>
                </div>
            </div>
            ):('')}
        </div>
    </section>
  );
};

export default Services2Section;