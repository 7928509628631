import React from "react";
import { useEffect } from "react";
import Layouts from "@layouts/Layouts";
import dynamic from "next/dynamic";
import { getSortedPostsData } from "@library/posts";
import { circleText } from "@common/utilits";
import AboutSection from "@components/sections/About";
import CountersSection from "@components/sections/Counters";
import CallToActionSection from "@components/sections/CallToAction";
import LatestPostsSection from "@components/sections/LatestPosts";
import PartnersSection from "@components/sections/Partners"
import ServicesSection from "@/src/components/sections/Services"
import Head from "next/head";

const Hero2Section = dynamic( () => import("@components/sliders/Hero2"), { ssr: false } );
const TestimonialSlider = dynamic( () => import("@components/sliders/Testimonial"), { ssr: false } );

const Home1 = (props) => {
  useEffect(() => {
    circleText();
  }, []);

  const schema = `{
    "@context": "https://schema.org",
    "@type": "Organization",
    "founder": "Suresh.R",
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "info@naidhruvatechnologies.com",
      "telephone": "+919491446694",
      "hoursAvailable": {
        "@type": "OpeningHoursSpecification",
        "opens": "10:00",
        "closes": "19:00"
      }
    },
    "email": "info@naidhruvatechnologies.com",
    "foundingLocation": {
      "@type": "Place",
      "name": "KPHB, Hyderabad 500085"
    },
    "legalName": "Naidhruva Technologies",
    "telephone": "+919491446694",
    "location": {
      "@type": "Place",
      "name": "Naidhruva Technologies",
      "address": [
        {
          "@type": "PostalAddress",
          "name": "KPHB, Hyderabad 500085"
        }
      ]
    },
    "logo": "https://naidhruvatechnologies.com/logo-main.png"
  }`;

  return (
    <Layouts>
      <>
        <Head>
          <title>Driving brand success with digital strategies - Naidhruva Technologies | Home</title>
          <meta name="description" content="Empowering brands with tailored digital strategies to drive rapid success in the digital realm"></meta>
          <meta property="og:title" content={`Driving brand success with digital strategies - Naidhruva Technologies`}></meta>
          <meta property="og:description" content={`Empowering brands with tailored digital strategies to drive rapid success in the digital realm`}></meta>
          <meta property="og:site_name" content="Naidhruva Technologies"></meta>
          <meta property="og:url" content="https://naidhruvatechnologies.com/"></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:image" content={`https://naidhruvatechnologies.com/images/og.png`}></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:domain" content="naidhruvatechnologies.com"></meta>
          <meta property="twitter:url" content="https://naidhruvatechnologies.com/"></meta>
          <meta name="twitter:title" content={`Driving brand success with digital strategies - Naidhruva Technologies`}></meta>
          <meta name="twitter:description" content={`Empowering brands with tailored digital strategies to drive rapid success in the digital realm`}></meta>
          <meta name="twitter:image" content={`https://naidhruvatechnologies.com/images/og.png`}></meta>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schema }}
          /> 
        </Head>
        <Hero2Section />
        <AboutSection />
        <ServicesSection />
        <CallToActionSection />
        <LatestPostsSection posts={props.posts} />
        <TestimonialSlider />
        <PartnersSection />
        <CountersSection />
      </>
    </Layouts>
  );
};
export default Home1;


export async function getStaticProps() {
  const allPosts = getSortedPostsData();
  return {
    props: {
      posts: allPosts,
    }
  }
}
